import React from 'react'
import { BounceLoader } from 'react-spinners'
import { graphql } from 'gatsby'
import { useState } from 'react'
import { ReactComponent as Cross } from './cross.svg'

import { pageData } from './queries'
import useQuery from './useQuery'

export const Preview = props => {

  const { element, pageProps, placeholder } = props
  const { pageContext } = pageProps

  const params = new URLSearchParams(pageProps?.location?.search)
  const postId = params.get('id')
  const postDatabaseId = params.get('databaseId')
  const jwtAuthKey = params.get('key')

  const { query, previewOptions, pageTemplate, postType } = pageContext || {}

  const debugLog = message => {
    previewOptions?.debug &&
      console.log(`[gatsby-plugin-wordpress-preview] `, message)
  }
  console.log(pageTemplate, postType)

  const [executeQuery, { error, data, called, loading }] = useQuery({
    url: previewOptions?.graphqlEndpoint,
    variables: { id: postId, databaseId: postDatabaseId },
    query: query,
    headers: { Authorization: `Bearer ${jwtAuthKey}` },
  })

  !called && executeQuery()
  debugLog({ query })
  debugLog({ postId, jwtAuthKey })
  debugLog({ previewOptions })

  debugLog({
    error,
    data,
    called,
    loading,
  })

  const previewData = data

  if (loading || placeholder || typeof window === `undefined`) {
    return (
      <LoaderContainer>
        <BounceLoader />
      </LoaderContainer>
    )
  } else if (previewData && !error) {
    return (
      <PreviewWindow>
        {React.Children.map(element, child =>
          React.cloneElement(child, { data: previewData?.data || data?.data })
        )}
      </PreviewWindow>
    )
  } else if (error) {
    return (
      <LoaderContainer>
        <p>Something went wrong. Please try again.</p>
        <p>{error?.errors && error.errors.map(({ message }) => message)}</p>
      </LoaderContainer>
    )
  } else {
    return (
      <LoaderContainer>
        <p>Something went wrong. Please try again.</p>
        <p>{error?.errors && error.errors.map(({ message }) => message)}</p>
      </LoaderContainer>
    )
  }
}

const LoaderContainer = props => (

  <PreviewWindow>
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '80vh',
      '> div': {
        margin: '15px 0',
      },
    }}
    {...props}
  />
  </PreviewWindow>
)

const PreviewWindow = props => {

  const [closed, setClosed] = useState(false)

  return (
    <>
      <div
        className=""
        style={{
          background: `#000`,
          color: '#fff',
          top: 0,
          width: '100%',
          textAlign: 'center',
          padding: '10px 0',
          position: 'sticky',
          zIndex: 9999,
          boxShadow: '1px 1px 18.7px rgba(0,0,0,0.5)',
          fontWeight: 700,
          display: closed ? "none" : "block",

        }}
      >
        Preview Page
        <Cross
          style={{
            height: "11px",
            width: "11px",
            position: "absolute",
            right: "20px",
            top: "15px",
          }}
          onClick={() => setClosed(true)}
        />
      </div>

      {props.children}
    </>
  )
}

export default Preview
