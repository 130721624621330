const pageData = `
query($id: ID! $databaseId: ID!) {

  allWpRegion:regions {
    nodes {
      title
    }
  }
  page(id:  $id ) {
    title
    avFooterScripts {
      ftScripts
    }
    ancestors {
      nodes {
        ... on Page {
          title
          uri
          depth
          menuOrder
          wpChildren:children {
            nodes {
              ... on Page {
                title
                uri
              }
            }
          }
        }
      }
    }
    uri
    templateSlug
    seo {
      title
      metaDesc
    }
    content
    categories {
      nodes {
        name
        slug
      }
    }
    avContent {
      featureImage {
        sourceUrl
        srcSet
      }
    }
    avFavList {
      flIntroText
      flItems {
        ... on Operator {
          id
          title
          databaseId
          uri
          excerpt
          avOperatorDetails {
            orgName
            text
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
          avContactInfo {
            venue
            address
            bookingUrl
            phone
            postcode
            state
            suburb
            website
            days {
              times
              day
            }
          }
        }
        ... on Event {
          id
          title
          databaseId
          uri
          excerpt
          avOperatorDetails {
            text
            orgName
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
          avEvents {
            dates {
              date
            }
            showTime
          }
          avContactInfo {
            venue
            address
            bookingUrl
            website
            suburb
            state
            postcode
            phone
          }
        }
      }
    }
    avIntro {
      introDescription
      introHeading
    }
    avContactInfo {
      venue
      address
      state
      postcode
      suburb
      phone
      email
      website
      days {
        day
        times
      }
    }
    avListingPage {
      handpickedContent {
        ... on Operator {
          title
          databaseId
          uri
          excerpt
          operatorTypes {
            nodes {
              name
              slug
            }
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
          avContactInfo {
            region {
              ... on Region {
                title
                slug
                regionField {
                  regionPage {
                    ... on Page {
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
        }
        ... on Event {
          title
          uri
          databaseId
          excerpt
          operatorTypes {
            nodes {
              name
              slug
            }
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
          avContactInfo {
            region {
              ... on Region {
                title
                slug
                regionField {
                  regionPage {
                    ... on Page {
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
        }

        ... on Page {
          title
          excerpt
          uri
          
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
    avRelatedPages {
      relatedHeading
      relatedPages {
        ... on Page {
          title
          uri
          excerpt
          categories {
            nodes {
              slug
              name
            }
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
        }
        ... on Itinerary {
          title
          uri
          excerpt
        
          avItinerary {
            summary {
              days
            }
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
  }

  
  allWpPage: pages(where: {parent: $databaseId, orderby: {field: MENU_ORDER, order: ASC}}) {
    nodes {
      title
      id
      slug
      title
      excerpt
      date
      menuOrder
      uri
      templateSlug
      parentDatabaseId
      avIntro {
        introHeading
        introDescription
      }
      avContent {
        featureImage {
          sourceUrl
          srcSet
        }
      }
    }
  }
  
}
`

const homeData = `
query ($id: ID!) {
  stories: posts(first: 4, where: {orderby: {field: DATE, order: DESC}}) {
    nodes {
      title
      excerpt
      uri
      categories {
        nodes {
          slug
          name
        }
      }
      primaryCat {
        node {
          name
          slug
        }
      }
      avContent {
        featureImage {
          sourceUrl
          srcSet
        }
      }
    }
  }
  page(id: $id) {
    title
    seo {
      title
      metaDesc
    }
    content
    categories {
      nodes {
        name
        slug
      }
    }
    journeysGallery {
      srcSet
      sourceUrl
    }
    avContent {
      featureImage {
        sourceUrl
        srcSet
      }
    }
    avIntro {
      introDescription
      introHeading
    }
    avContactInfo {
      venue
      address
      state
      postcode
      suburb
      phone
      email
      website
      days {
        day
        times
      }
    }
    avRelatedPages {
      relatedHeading
      relatedPages {
        ... on Page {
          title
          uri
          excerpt
          categories {
            nodes {
              slug
              name
            }
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
        }
        ... on Itinerary {
          title
          uri
          excerpt
          avItinerary {
            summary {
              days
            }
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
    mobUnearth {
      unearthctaDescription
      unearthctaHeading
      unearthctaImage1 {
        sourceUrl
        srcSet
      }
      unearthctaImage2 {
        sourceUrl
        srcSet
      }
      unearthctaLabel
      unearthctaLink
      unearthctaSubheading
    }
    avAttractionsMap {
      attractions {
        attraction {
          ... on Operator {
            title
            uri
            map {
              lat
              lng
            }
            avContent {
              featureImage {
                mediaDetails {
                  sizes {
                    sourceUrl
                  }
                }
              }
            }
          }
          ... on Page {
            title
            uri
            map {
              lat
              lng
            }
            avContent {
              featureImage {
                mediaDetails {
                  sizes {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
        icon
      }
    }
    avFeaturedContent {
      highlightBanner {
        hide
        heading
        image {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
        linkText
        link {
          ... on Page {
            uri
          }
          ... on Operator {
            uri
          }
          ... on Event {
            uri
          }
          ... on Post {
            uri
          }
        }
      }
      secondaryBanner {
        hide
        heading
        image {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
        linkText
        link {
          ... on Page {
            uri
          }
          ... on Operator {
            uri
          }
          ... on Event {
            uri
          }
          ... on Post {
            uri
          }
        }
      }
      itineraryWidget {
        categoryLabel
        itinerary {
          ... on Itinerary {
            title
            avContent {
              featureImage {
                sourceUrl
                srcSet
              }
            }
            uri
            excerpt
            avItinerary {
              summary {
                days
              }
              introText
            }
          }
        }
      }
      whatsOn {
        ... on Event {
          title
          uri
          databaseId
          excerpt
          avOperatorDetails {
            orgName
            categories {
              name
            }
          }
          avEvents {
            ticketStatus
            dates {
              date
            }
            showTime
          }
          avContent {
            featureImage {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
  }
}
`
const postData = `
query($id: ID!) {
  post(id:  $id ) {
    title
    date
    seo {
      title
      metaDesc
    }
    content
    primaryCat {
      node {
        name
        slug
      }
    }
    categories {
      nodes {
        name
        slug
      }
    }
    avContent {
      featureImage {
        sourceUrl
        srcSet
      }
    }
  }

}
`

const eventData = `
query($id: ID!) {

  event(id: $id) {
    databaseId
    excerpt


    title
    friendlyData {
      isMultiSession
    }
    operatorTypes {
      nodes {
        name
        slug
      }
    }
    seo {
      title
      metaDesc
    }
    map {
      lat
      lng
    }

    avEvents {
      dates {
        date
        startTime
        endTime
      }
      showTime
    }
    avOperatorDetails {
      text
      orgName
      widget
      bookingPopup
      videos {
        url
      }
    }
    avContactInfo {
      venue
      address
      state
      postcode
      suburb
      phone
      bookingUrl
      email
      website
      days {
        day
        times
      }
    }
    gallery {
      sourceUrl
      srcSet
    }
    avContent {
      featureImage {
        sourceUrl
        srcSet
      }
    }

  }



}
`

const operatorData = `
query($id: ID!) {

  operator(id: $id) {
    databaseId
    excerpt


    title
    operatorTypes {
      nodes {
        name
        slug
      }
    }
    seo {
      title
      metaDesc
    }
    map {
      lat
      lng
    }
    avOperatorDetails {
      text
      orgName
      widget
      videos {
        url
      }
    }
    avContactInfo {
      venue
      address
      state
      postcode
      suburb
      phone
      bookingUrl
      email
      website
      days {
        day
        times
      }
    }
    gallery {
      sourceUrl
      srcSet
    }
    avContent {
      featureImage {
        sourceUrl
        srcSet
      }
    }

  }



}
`

const itineraryData = `


query($id: ID!) {
  wpItinerary:itinerary(id: $id) {
    title
    seo {
      metaDesc
      title
    }
    avContent {
      featureImage {
        sourceUrl
        srcSet
      }
    }
    avItinerary {
      introText
      alpaca
      summary {
        days
      }
    }
  }
  
  itineraryPool: itineraries {
    nodes {
      title
      uri
      avContent {
        featureImage {
          sourceUrl
          srcSet
        }
      }
      avItinerary {
        summary {
          days
        }
      }
    }
  }
}

`

module.exports = {
  pageData,
  postData,
  homeData,
  itineraryData,
  eventData,
  operatorData,
}
