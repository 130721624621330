import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import fetch from "cross-fetch"
const cache = new InMemoryCache();

let bPreview = false;
let token = '';

if (typeof window !== `undefined`) {
  const aUrlPath = window?.location.pathname.split("/") || []
  token = aUrlPath[aUrlPath?.length - 1]
  bPreview = aUrlPath[1]?.toLowerCase() === "preview" || false
}
export const link = createHttpLink({
  fetch,
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT
})

export const client = new ApolloClient({
  cache,
  link, 
  headers: {
    Authorization: bPreview ? `Bearer ${token}` : "",
  },
})
