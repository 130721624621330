// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-favourites-js": () => import("./../../../src/pages/favourites.js" /* webpackChunkName: "component---src-pages-favourites-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-category-js": () => import("./../../../src/templates/page-category.js" /* webpackChunkName: "component---src-templates-page-category-js" */),
  "component---src-templates-page-favouriteslist-custom-js": () => import("./../../../src/templates/page-favouriteslist-custom.js" /* webpackChunkName: "component---src-templates-page-favouriteslist-custom-js" */),
  "component---src-templates-page-itinerarylisting-js": () => import("./../../../src/templates/page-itinerarylisting.js" /* webpackChunkName: "component---src-templates-page-itinerarylisting-js" */),
  "component---src-templates-page-newslisting-js": () => import("./../../../src/templates/page-newslisting.js" /* webpackChunkName: "component---src-templates-page-newslisting-js" */),
  "component---src-templates-page-subcategory-js": () => import("./../../../src/templates/page-subcategory.js" /* webpackChunkName: "component---src-templates-page-subcategory-js" */),
  "component---src-templates-page-whatson-js": () => import("./../../../src/templates/page-whatson.js" /* webpackChunkName: "component---src-templates-page-whatson-js" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/single/event.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-itinerary-js": () => import("./../../../src/templates/single/itinerary.js" /* webpackChunkName: "component---src-templates-single-itinerary-js" */),
  "component---src-templates-single-operator-js": () => import("./../../../src/templates/single/operator.js" /* webpackChunkName: "component---src-templates-single-operator-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single/page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

