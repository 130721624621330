import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './src/apollo/client'

import Preview from './src/preview/Preview'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
  }
}

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props

  if (!!pageContext.preview) {
    return <Preview pageProps={props} element={element} />
  } else {
    return element
  }
}
